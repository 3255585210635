

















































import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import TranslateableTextarea from '@/components/common/translateable-textarea.vue';
import SafetyInstructionItemContent from '@/features/safety-instructions/components/safety-instruction-item-content.vue';
import { SafetyInstructions } from '@/models/venues/safety-instructions';
import { SafetyInstructionItem } from '@/models/venues/safety-instruction-item';
import { plainToClass } from 'class-transformer';

@Component({
  name: 'SafetyInstructionsContent',
  components: {
    TranslateableTextarea,
    SafetyInstructionItemContent,
  },
})
export default class SafetyInstructionsContent extends mixins(FormFunctions) {
  @Prop()
  venue: IVenue;

  get items () {
    return this.venue.safetyInstructions.instructionItems || [];
  }

  get maxPosition () {
    return Math.max(...this.items.map(o => o.position), 0);
  }

  get minPosition () {
    return Math.min(...this.items.map(o => o.position), 0);
  }

  created () {
    if (!this.venue.safetyInstructions) {
      this.venue.safetyInstructions = plainToClass(SafetyInstructions, {});
    }
    this.items.sort((a, b) => a.position - b.position);
  }

  addNewInstruction () {
    const safetyConstructionItem = plainToClass(SafetyInstructionItem, {});
    safetyConstructionItem.position = this.maxPosition + 1;
    this.items.push(safetyConstructionItem);
    this.items.sort((a, b) => a.position - b.position);
  }

  deleteItem (position: number) {
    this.items.splice(this.items.findIndex(x => x.position === position), 1);
  }

  movePosition (position: number, upOrDown: 'up' | 'down') {
    const newPosition = (upOrDown === 'up') ? position - 1 : position + 1;

    const currentItem = this.items.find(x => x.position === position);
    const nextItem = this.items.find(x => x.position === newPosition);
    currentItem.position = newPosition;
    nextItem.position = position;
    this.items.sort((a, b) => a.position - b.position);
  }

  positionUp (index: number) {
    this.movePosition(index, 'up');
  }

  positionDown (index: number) {
    this.movePosition(index, 'down');
  }
}
