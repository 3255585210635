




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ISafetyInstructionItem } from '@einfachgast/shared';
import TranslateableTextarea from '@/components/common/translateable-textarea.vue';
import { isFileSizeExceeded } from '@/helpers/file-helper';

@Component({
  name: 'SafetyInstructionItemContent',
  components: {
    TranslateableTextarea,
  },
})
export default class SafetyInstructionItemContent extends Vue {
  maxIcons = 5;

  @Prop()
  maxPosition: number;

  @Prop()
  safetyInstructionItem: ISafetyInstructionItem;

  get isFirstItem () {
    return this.safetyInstructionItem.position === 1;
  }

  get isLastItem () {
    return this.maxPosition === this.safetyInstructionItem.position;
  }

  async addIcon (icon: File) {
    // 10000bytes = 10kb
    if (isFileSizeExceeded(icon, 15000)) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Maximale Dateigröße von 15KB überschritten.',
        position: 'is-top',
        type: 'is-danger',
      });
    } else {
      const base64Icon = (await this.toBase64(icon) as string);
      this.safetyInstructionItem.iconUrls.push(base64Icon);
    }
  }

  async toBase64 (file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  positionUp () {
    if (this.isFirstItem) {
      return;
    }
    this.$emit('positionUp', this.safetyInstructionItem.position);
  }

  positionDown () {
    if (this.isLastItem) {
      return;
    }
    this.$emit('positionDown', this.safetyInstructionItem.position);
  }

  deleteIcon (index: number) {
    this.safetyInstructionItem.iconUrls.splice(index, 1);
  }
}
